import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => {
  const title = 'Home';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <p>
        Welcome to the official website of the{' '}
        <strong>Kongsir &amp; Eddie Wing Chun Family Tree System</strong>!
      </p>

      <p>
        Our “Learn Wing Chun, Learn English” project has come to an end. (If you
        want to know more about the project, you can still access the Background
        Information page and the Media Publications (中/ENG) page for details.)
        In order to enable our unique Wing Chun to reach as many people as
        possible in different walks of life, instead of some selected groups, we
        are now starting Wing Chun classes that are open to the general public.{' '}
        <Link to="/ad">Please refer to this ad</Link> (in Chinese only because
        the medium of instruction will be Cantonese).
      </p>

      <p>
        我們的「學詠春、學英文」計劃已經結束。(如果你仍然有興趣去了解計劃詳情，你可瀏覧我們的Background
        Information及 Media Publications (中/ENG) 網頁。)
        為了將我們極為超卓的詠春拳廣泛介紹到社會各階層的人士，而不是某些指定的組別，我們將會舉辦公開的詠春班
        (以粵語授課)。 <Link to="/ad">詳情請看廣告</Link>。
      </p>
    </Layout>
  );
};

export default IndexPage;
